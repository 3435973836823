import React from "react";

export default function Footer(props) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer_panel">
          <p>Smart-contract:</p>
          <a
            href="https://testnet.bscscan.com/address/0xab91BdedE23947EAE8588cc55af3103b3616045f"
            target="_blank"
            style={{ color: "white" }}
            className="text-break"
          >
            0xab91BdedE23947EAE8588cc55af3103b3616045f
          </a>
          <p className="blue">Support : support@polyfund.com</p>
          <div className="footer_panel_right">
            <ul className="footer_social_links">
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-telegram-plane"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
