import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import AOS from "aos";
import "aos/dist/aos.css";
import { Scrollbars } from "react-custom-scrollbars";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";
import polyfundcontractabi from "../ABI/polyfundcontractabi.json";
import Web3 from "web3";
import axios from "axios";
import config from "../lib/config";
import syncEach from "sync-each";
import abiDecoder from "abi-decoder";
import moment from "moment";

abiDecoder.addABI(polyfundcontractabi);

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home(props) {
  useEffect(() => {
    AOS.init();
  }, []);

  const walletConnection = useSelector((state) => state.walletConnection);
  const [latesttrx, setlatesttrx] = useState([]);
  const [totalusers, settotalusers] = useState(0);
  const [totalpayout, settotalpayout] = useState(0);

  async function movingnext() {
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        walletConnection.userdetails.isExist
      ) {
        window.location.href = "/dashboard";
      } else {
        window.location.href = "/join-now";
      }
    } else {
      window.location.href = "/join-now";
    }
  }

  useEffect(() => {
    getrecentactivity();
    getdetails();
  }, [walletConnection.address]);

  async function getdetails() {
    const web3_bsc = new Web3(config.dataUrl); //local
    var polygonContract = new web3_bsc.eth.Contract(
      polyfundcontractabi,
      config.polyfundcontract
    );
    let totalUsers = await polygonContract.methods.totalUsers().call();
    settotalusers(totalUsers);
    let totalpayout = await polygonContract.methods.totalPayouts().call();
    settotalpayout(totalpayout);
  }

  async function getrecentactivity() {
    try {
      let respData = await axios({
        method: "get",
        url: `https://api-testnet.bscscan.com/api?module=account&action=txlist&address=0x70cda58145DD88aaCA9EDc9464e237fE9D9c82df&page=1&offset=10&sort=desc&apikey=FH7AQA2ES1JFBF979QCZRSU9AX87ZM5MRN`,
      });

      console.log(respData.data.result, "respDatarmuviespData");
      const web3_bsc = new Web3(config.dataUrl); //local
      var polygonContract = new web3_bsc.eth.Contract(
        polyfundcontractabi,
        config.polyfundcontract
      );
      let arrrec = [];
      if (
        respData.data &&
        respData.data.result &&
        respData.data.result.length > 0
      ) {
        syncEach(
          respData.data.result,
          async function (items, next) {
            let trans_hash = await web3_bsc.eth.getTransaction(items.hash);
            if (trans_hash && trans_hash.input) {
              let trans_detail = abiDecoder.decodeMethod(trans_hash.input);

              let userdet = await polygonContract.methods
                .users(items.from)
                .call();

              let date = moment.unix(items.timeStamp).toDate();

              var now = moment(new Date()); //todays date
              var end = moment(date); // another date

              var duration = now.diff(end, "minutes");
              var duration1 = now.diff(end, "hours");

              if (
                trans_detail &&
                trans_detail.name &&
                trans_detail.name == "regUser"
              ) {
                let datas = {
                  address: web3_bsc.utils.toChecksumAddress(items.from),
                  userid: userdet.id,
                  event: trans_detail.name,
                  value: 0,
                  trx: items.hash,
                  min: duration,
                  hours: duration1,
                };
                arrrec.push(datas);
                next();
              } else {
                next();
              }
            } else {
              next();
            }
          },
          function (err, transformedItems) {
            console.log(arrrec, "arrrec");
            const filteredPeople = arrrec.filter((item) => item.userid !== "0");

            setlatesttrx(filteredPeople);
          }
        );
      }
    } catch (err) {
      console.log(err, "=======errrrrrrrrrrrr=======");
    }
  }

  return (
    <div>
      {/* <ScrollToTopOnMount />   */}
      <Navbar />
      <div className="page_header">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 m-auto"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h1>
                Your most awaited <span> Decentralized Matrix Platform</span>
              </h1>
              <div className="row">
                <div className="col-lg-7">
                  <p>
                    Earn maximum by investing minimum. Discover the newest
                    treasures with Polyfund.
                  </p>
                </div>
              </div>
              <button className="primary_btn" onClick={() => movingnext()}>
                Join Now
              </button>
            </div>
            <div
              className="col-lg-6"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="img_box">
                <img
                  src={require("../assets/images/banimg.png")}
                  className="img-fluid"
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main_wrapper">
        <section className="total">
          <div className="container">
            <div className="row">
              <div
                className="col-xl-10 col-xxl-9 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="tot_box">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb1.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <h3>
                            {totalpayout
                              ? parseFloat(totalpayout) / config.decimals
                              : 0}
                          </h3>
                          <p>Total Pay-out (BNB)</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="box right_box">
                        <div className="img_box">
                          <img
                            src={require("../assets/images/tb2.png")}
                            className="img-fluid"
                          />{" "}
                        </div>
                        <div>
                          <h3>{totalusers}</h3>
                          <p>Total Users</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="features">
          <div className="container">
            <div
              className="head pb-5"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2 className="h2tag">
                What we can do for you? <span> Features</span>
              </h2>
            </div>
            <div className="row">
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="100"
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/f1.png")}
                      className="img-fluid"
                    />
                  </div>
                  <h6>Smart Contract on Blockchain</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="300"
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/f2.png")}
                      className="img-fluid"
                    />
                  </div>
                  <h6>Web3 100% Automated System</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-4"
                data-aos="fade-up"
                data-aos-duration="1000"
                data-aos-delay="500"
              >
                <div className="box">
                  <div className="img_box">
                    <img
                      src={require("../assets/images/f3.png")}
                      className="img-fluid"
                    />
                  </div>
                  <h6>Are the funds safe here</h6>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Quis ipsum suspendisse ultrices gravida.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="levels">
          <div className="container">
            <div
              className="head pb-3"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2 className="h2tag">
                {" "}
                <span> Level Explain</span>
              </h2>
            </div>
            <div
              className="row"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
            >
              <div className="col-lg-9 mx-auto">
                <div class="table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Levels</th>
                        <th>Members</th>
                        <th>Matic</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Level 1</td>
                        <td>3</td>
                        <td>30</td>
                      </tr>
                      <tr>
                        <td>Level 2</td>
                        <td>9</td>
                        <td>90</td>
                      </tr>
                      <tr>
                        <td>Level 3</td>
                        <td>27</td>
                        <td>270</td>
                      </tr>
                      <tr>
                        <td>Level 4</td>
                        <td>81</td>
                        <td>810</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="platform_activity ">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag mb-5">
                <span> Platform Recent Activity</span>
              </h2>
            </div>
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div
                  className="activity_box"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <Scrollbars style={{ width: "100%", height: 390 }}>
                    {console.log(latesttrx, "latesttrxlatesttrx")}
                    {latesttrx &&
                      latesttrx.length > 0 &&
                      latesttrx.map((item) => (
                        <div className="activity_scroll">
                          <div
                            className="activity_box_flx"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                            data-aos-delay="100"
                          >
                            <div>
                              <div className="flxbox">
                                <div>
                                  <img
                                    src={require("../assets/images/user_prf.png")}
                                    className="img-fluid"
                                    alt="img"
                                  />
                                </div>
                                <div>
                                  <p className="violet">New user joined</p>
                                </div>
                                <div>
                                  <div className="btn">ID {item?.userid}</div>
                                </div>
                              </div>
                            </div>
                            <div className="rightbtn">
                              <p>
                                <span>
                                  {" "}
                                  <a href="#" target="_blank">
                                    <img
                                      src={require("../assets/images/arrow.png")}
                                      className="img-fluid me-2"
                                      alt="img"
                                    />
                                  </a>
                                </span>
                                <span>
                                  {" "}
                                  {item.hours > 0 ? item.hours : item.min}{" "}
                                  {item.hours > 0 ? " Hours" : " Min"}{" "}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                  </Scrollbars>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="tree_sec"
          data-aos="fade-up"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <div className="container">
            <div className="bg">
              <h2 className="h2tag head pb-5">
                <span>Genealogy Tree</span>
              </h2>
              <div className="row">
                <div
                  className="col-lg-7"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="500"
                >
                  <img
                    src={require("../assets/images/tree.png")}
                    className="img-fluid"
                  />
                </div>
                <div
                  className="col-lg-5 m-auto"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="700"
                >
                  <div className="in_box">
                    <div className="box bdr">
                      <p>Direct Referral</p>
                      <h5>10%</h5>
                    </div>
                    <div className="box">
                      <p>Generation income </p>
                      <h5>20%</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="faq">
          <div className="container">
            <div
              className="head pb-4"
              data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="100"
            >
              <h2 className="h2tag text-center mb-3">
                <span>FAQ</span>
              </h2>
              <p>
                You can send email with your questions and we'll give your
                answer
              </p>
            </div>
            <div className="row">
              <div className="col-lg-9 mx-auto">
                <div class="accordion" id="accordionExample">
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="100"
                  >
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button">
                        What is Polyfund?
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Polyfund is a decentralized networking platform that
                          operates on the blockchain. It offers easy earning
                          opportunities for members through referrals.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="200"
                  >
                    <h2 class="accordion-header" id="headingTwo">
                      <button class="accordion-button collapsed" type="button">
                        How many levels of total income streams are there with
                        Polyfund?
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore elit,
                          sed do eiusmod tempor incididunt ut labore{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="300"
                  >
                    <h2 class="accordion-header" id="headingThree">
                      <button class="accordion-button collapsed" type="button">
                        What is the minimum Staking Amount required to join
                        Polyfund?
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore elit,
                          sed do eiusmod tempor incididunt ut labore{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="accordion-item"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapsefour"
                    aria-expanded="false"
                    aria-controls="collapsefour"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay="400"
                  >
                    <h2 class="accordion-header" id="headingfour">
                      <button class="accordion-button collapsed" type="button">
                        How secure is my Staking with Polyfund?
                      </button>
                    </h2>
                    <div
                      id="collapsefour"
                      class="accordion-collapse collapse"
                      aria-labelledby="headingfour"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <p className="para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore elit,
                          sed do eiusmod tempor incididunt ut labore{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <Footer />
      </div>
    </div>
  );
}
