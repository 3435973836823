import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import { Scrollbars } from "react-custom-scrollbars";
import Web3 from "web3";
import config from "../lib/config";
import { toast } from "react-toastify";
import { connection } from "../helper/connection";
import { useSelector } from "react-redux";
import polyfundcontractabi from "../ABI/polyfundcontractabi.json";
import { Multicall } from "ethereum-multicall";
import syncEach from "sync-each";
import BigNumber from "bignumber.js";
import Spinner from "react-bootstrap/Spinner";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Countdown, { zeroPad } from "react-countdown";

export default function Dashboard(props) {
  let toasterOption = config.toasterOption;
  const walletConnection = useSelector((state) => state.walletConnection);
  const [referaladdress, setreferaladdress] = useState("");
  const [progress, setprogress] = useState(false);
  const [treeprogress, settreeprogress] = useState(false);
  const [userdetails, setuserdetails] = useState({});
  const [selected, setSelected] = useState({});
  const [searchid, setsearchid] = useState("");
  const [alltreedata, setalltreedata] = useState({});

  useEffect(() => {
    getdetails();
  }, [walletConnection.address]);

  useEffect(() => {
    if (searchid == "") {
      getdetails();
    }
  }, [searchid]);

  useEffect(() => {
    if (selected) {
      setDatas();
    }
  }, [selected]);

  async function setDatas() {
    let tree1 = selected && selected?.name ? selected?.name : "";
    let tree2 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.name
        ? selected?.children?.[0]?.name
        : "";
    let tree3 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.name
        ? selected?.children?.[1]?.name
        : "";
    let tree4 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.name
        ? selected?.children?.[2]?.name
        : "";
    let tree5 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.name
        ? selected?.children?.[0]?.children?.[0]?.name
        : "";
    let tree6 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.name
        ? selected?.children?.[0]?.children?.[1]?.name
        : "";
    let tree7 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.name
        ? selected?.children?.[0]?.children?.[2]?.name
        : "";
    let tree8 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.name
        ? selected?.children?.[1]?.children?.[0]?.name
        : "";
    let tree9 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.name
        ? selected?.children?.[1]?.children?.[1]?.name
        : "";
    let tree10 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.name
        ? selected?.children?.[1]?.children?.[2]?.name
        : "";
    let tree11 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.name
        ? selected?.children?.[2]?.children?.[0]?.name
        : "";
    let tree12 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.name
        ? selected?.children?.[2]?.children?.[1]?.name
        : "";
    let tree13 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.name
        ? selected?.children?.[2]?.children?.[2]?.name
        : "";
    let tree14 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.children[0]?.name
        ? selected?.children?.[0]?.children?.[0]?.children[0]?.name
        : "";
    let tree15 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.children[1]?.name
        ? selected?.children?.[0]?.children?.[0]?.children[1]?.name
        : "";
    let tree16 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[0]?.children[2]?.name
        ? selected?.children?.[0]?.children?.[0]?.children[2]?.name
        : "";
    let tree17 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.children[0]?.name
        ? selected?.children?.[0]?.children?.[1]?.children[0]?.name
        : "";
    let tree18 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.children[1]?.name
        ? selected?.children?.[0]?.children?.[1]?.children[1]?.name
        : "";
    let tree19 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[1]?.children[2]?.name
        ? selected?.children?.[0]?.children?.[1]?.children[2]?.name
        : "";
    let tree20 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.children[0]?.name
        ? selected?.children?.[0]?.children?.[2]?.children[0]?.name
        : "";
    let tree21 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.children[1]?.name
        ? selected?.children?.[0]?.children?.[2]?.children[1]?.name
        : "";
    let tree22 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[0]?.children?.[2]?.children[2]?.name
        ? selected?.children?.[0]?.children?.[2]?.children[2]?.name
        : "";
    let tree23 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.children[0]?.name
        ? selected?.children?.[1]?.children?.[0]?.children[0]?.name
        : "";
    let tree24 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.children[1]?.name
        ? selected?.children?.[1]?.children?.[0]?.children[1]?.name
        : "";
    let tree25 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[0]?.children[2]?.name
        ? selected?.children?.[1]?.children?.[0]?.children[2]?.name
        : "";
    let tree26 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.children[0]?.name
        ? selected?.children?.[1]?.children?.[1]?.children[0]?.name
        : "";
    let tree27 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.children[1]?.name
        ? selected?.children?.[1]?.children?.[1]?.children[1]?.name
        : "";
    let tree28 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[1]?.children[2]?.name
        ? selected?.children?.[1]?.children?.[1]?.children[2]?.name
        : "";
    let tree29 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.children[0]?.name
        ? selected?.children?.[1]?.children?.[2]?.children[0]?.name
        : "";
    let tree30 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.children[1]?.name
        ? selected?.children?.[1]?.children?.[2]?.children[1]?.name
        : "";
    let tree31 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[1]?.children?.[2]?.children[2]?.name
        ? selected?.children?.[1]?.children?.[2]?.children[2]?.name
        : "";
    let tree32 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.children[0]?.name
        ? selected?.children?.[2]?.children?.[0]?.children[0]?.name
        : "";
    let tree33 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.children[1]?.name
        ? selected?.children?.[2]?.children?.[0]?.children[1]?.name
        : "";
    let tree34 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[0]?.children[2]?.name
        ? selected?.children?.[2]?.children?.[0]?.children[2]?.name
        : "";
    let tree35 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.children[0]?.name
        ? selected?.children?.[2]?.children?.[1]?.children[0]?.name
        : "";
    let tree36 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.children[1]?.name
        ? selected?.children?.[2]?.children?.[1]?.children[1]?.name
        : "";
    let tree37 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[1]?.children[2]?.name
        ? selected?.children?.[2]?.children?.[1]?.children[2]?.name
        : "";
    let tree38 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.children[0]?.name
        ? selected?.children?.[2]?.children?.[2]?.children[0]?.name
        : "";
    let tree39 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.children[1]?.name
        ? selected?.children?.[2]?.children?.[2]?.children[1]?.name
        : "";
    let tree40 =
      selected &&
      selected?.children?.length > 0 &&
      selected?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.children?.length > 0 &&
      selected?.children?.[2]?.children?.[2]?.children[2]?.name
        ? selected?.children?.[2]?.children?.[2]?.children[2]?.name
        : "";

    setalltreedata({
      tree1: tree1,
      tree2: tree2,
      tree3: tree3,
      tree4: tree4,
      tree5: tree5,
      tree6: tree6,
      tree7: tree7,
      tree8: tree8,
      tree9: tree9,
      tree10: tree10,
      tree11: tree11,
      tree12: tree12,
      tree13: tree13,
      tree14: tree14,
      tree15: tree15,
      tree16: tree16,
      tree17: tree17,
      tree18: tree18,
      tree19: tree19,
      tree20: tree20,
      tree21: tree21,
      tree22: tree22,
      tree23: tree23,
      tree24: tree24,
      tree25: tree25,
      tree26: tree26,
      tree27: tree27,
      tree28: tree28,
      tree29: tree29,
      tree30: tree30,
      tree31: tree31,
      tree32: tree32,
      tree33: tree33,
      tree34: tree34,
      tree35: tree35,
      tree36: tree36,
      tree37: tree37,
      tree38: tree38,
      tree39: tree39,
      tree40: tree40,
    });
  }

  async function getdetails() {
    if (
      walletConnection &&
      walletConnection.address &&
      walletConnection.address != undefined &&
      walletConnection.address != null &&
      walletConnection.address != ""
    ) {
      if (
        walletConnection.userdetails &&
        !walletConnection.userdetails.isExist
      ) {
        window.location.href = "/join-now";
      } else {
        getuserdetails(walletConnection.address);
        getdashboarddetails(walletConnection.address);
      }
    }
  }
  async function getdashboarddetails() {
    try {
      setprogress(true);
      let web3call = new Web3(
        walletConnection &&
        walletConnection.provider &&
        walletConnection.provider != null &&
        walletConnection.provider != undefined &&
        walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );

      let address = walletConnection.address;

      const multicall = new Multicall({
        web3Instance: web3call,
      });

      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.polyfundcontract,
          abi: polyfundcontractabi,
          calls: [
            {
              reference: "userprofile",
              methodName: "users",
              methodParameters: [address],
            },
            {
              reference: "viewallDirectUserReferral",
              methodName: "viewallDirectUserReferral",
              methodParameters: [address],
            },
            {
              reference: "viewUserReferral",
              methodName: "viewUserReferral",
              methodParameters: [address],
            },
            {
              reference: "viewallgenerationIncome",
              methodName: "viewallgenerationIncome",
              methodParameters: [address],
            },
          ],
        },
      ];

      const results = await multicall.call(contractCallContext);
      var userprofile = await getFormatMulticall(results, "userprofile", 0);

      var viewallgenerationIncome = await getFormatMulticall(
        results,
        "viewallgenerationIncome",
        0
      );
      var myreferral = await getFormatMulticall(
        results,
        "viewallDirectUserReferral",
        0
      );

      console.log(userprofile, "userprofileuserprofile");
      if (userprofile[0]) {
        let userexist = true;
        let userid = await bignumber(userprofile[1]);
        let directrefferalCount = await myreferral.length;
        let DirectReferralIncome = await bignumber(userprofile[3]);
        let Generationincome1 = await bignumber(viewallgenerationIncome[0]);
        let Generationincome2 = await bignumber(viewallgenerationIncome[1]);
        let Generationincome3 = await bignumber(viewallgenerationIncome[2]);
        let Generationincome4 = await bignumber(viewallgenerationIncome[3]);
        let TotalGenerationIncome = await bignumber(userprofile[5]);
        // let ReJoinCount = await bignumber(userprofile[7]);
        // let RejoinTopupAmount = await bignumber(userprofile[6]);

        let data_user = {
          userexist: userexist,
          userid: userid,
          directrefferalCount: directrefferalCount,
          DirectReferralIncome: DirectReferralIncome,
          Generationincome1: Generationincome1,
          Generationincome2: Generationincome2,
          Generationincome3: Generationincome3,
          Generationincome4: Generationincome4,
          TotalGenerationIncome: TotalGenerationIncome,
          // ReJoinCount: ReJoinCount,
          // RejoinTopupAmount: RejoinTopupAmount,
        };
        // console.log(data_user, "data_user");
        setuserdetails(data_user);
      }
      setprogress(false);
    } catch (err) {
      setprogress(false);
      console.log(err, "err");
    }
  }

  async function getuserdetails(addr) {
    settreeprogress(true);
    try {
      let web3call = new Web3(
        walletConnection &&
        walletConnection.provider &&
        walletConnection.provider != null &&
        walletConnection.provider != undefined &&
        walletConnection.provider != ""
          ? walletConnection.provider
          : window.ethereum
      );

      let address = addr;

      const multicall = new Multicall({
        web3Instance: web3call,
      });

      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.polyfundcontract,
          abi: polyfundcontractabi,
          calls: [
            {
              reference: "userprofile",
              methodName: "users",
              methodParameters: [address],
            },
            {
              reference: "viewallDirectUserReferral",
              methodName: "viewallDirectUserReferral",
              methodParameters: [address],
            },
            {
              reference: "viewUserReferral",
              methodName: "viewUserReferral",
              methodParameters: [address],
            },
          ],
        },
      ];

      const results = await multicall.call(contractCallContext);
      var userprofile = await getFormatMulticall(results, "userprofile", 0);

      var downlinenode = await getFormatMulticall(
        results,
        "viewUserReferral",
        0
      );

      var myreferral = await getFormatMulticall(
        results,
        "viewallDirectUserReferral",
        0
      );

      if (userprofile[0]) {
        let userid = await bignumber(userprofile[1]);

        let _my_ref = [];
        if (myreferral.length > 0) {
          for (let i in myreferral) {
            var _myref = [
              {
                reference: "details",
                contractAddress: config.polyfundcontract,
                abi: polyfundcontractabi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [myreferral[i]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [myreferral[i]],
                  },
                ],
              },
            ];
            const _results = await multicall.call(_myref);
            var _userprofile = await getFormatMulticall(
              _results,
              "userprofile",
              0
            );
            var _downlinenode = await getFormatMulticall(
              _results,
              "viewUserReferral",
              0
            );
            let _userid = await bignumber(_userprofile[1]);
            let _downline = {
              address: myreferral[i],
              userid: _userid,
              downlinenode: _downlinenode,
            };
            _my_ref.push(_downline);
          }
        }

        console.log(_my_ref, "_my_ref");

        let downline1 = [];
        let downline2 = [];
        let downline3 = [];
        let downline4 = [];
        let downline5 = [];
        let downline2arr = [];
        let downline3arr = [];
        let downline4arr = [];
        let downline5arr = [];
        let downline6arr = [];

        ///one************************
        if (downlinenode.length > 0) {
          for (let node in downlinenode) {
            var downlineCallContext = [
              {
                reference: "details",
                contractAddress: config.polyfundcontract,
                abi: polyfundcontractabi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downlinenode[node]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downlinenode[node]],
                  },
                ],
              },
            ];
            const results1 = await multicall.call(downlineCallContext);
            var userprofile1 = await getFormatMulticall(
              results1,
              "userprofile",
              0
            );
            var downlinenode1 = await getFormatMulticall(
              results1,
              "viewUserReferral",
              0
            );
            let userid1 = await bignumber(userprofile1[1]);
            let downline_1 = {
              address: downlinenode[node],
              userid: userid1,
              downlinenode: downlinenode1,
            };
            downline1.push(downline_1);
            downline2arr = downline2arr.concat(downlinenode1);
          }
        }
        ///two************************
        if (downline2arr.length > 0) {
          for (let node1 in downline2arr) {
            var downlineCallContext0 = [
              {
                reference: "details",
                contractAddress: config.polyfundcontract,
                abi: polyfundcontractabi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline2arr[node1]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline2arr[node1]],
                  },
                ],
              },
            ];
            const results2 = await multicall.call(downlineCallContext0);
            var userprofile2 = await getFormatMulticall(
              results2,
              "userprofile",
              0
            );
            var downlinenode2 = await getFormatMulticall(
              results2,
              "viewUserReferral",
              0
            );
            let userid2 = await bignumber(userprofile2[1]);
            let downline_2 = {
              address: downline2arr[node1],
              userid: userid2,
              downlinenode: downlinenode2,
            };
            downline2.push(downline_2);
            downline3arr = downline3arr.concat(downlinenode2);
          }
        }
        ///thre************************
        if (downline3arr.length > 0) {
          for (let node2 in downline3arr) {
            var downlineCallContext1 = [
              {
                reference: "details",
                contractAddress: config.polyfundcontract,
                abi: polyfundcontractabi,
                calls: [
                  {
                    reference: "userprofile",
                    methodName: "users",
                    methodParameters: [downline3arr[node2]],
                  },
                  {
                    reference: "viewUserReferral",
                    methodName: "viewUserReferral",
                    methodParameters: [downline3arr[node2]],
                  },
                ],
              },
            ];
            const results3 = await multicall.call(downlineCallContext1);
            var userprofile3 = await getFormatMulticall(
              results3,
              "userprofile",
              0
            );
            var downlinenode3 = await getFormatMulticall(
              results3,
              "viewUserReferral",
              0
            );
            let userid3 = await bignumber(userprofile3[1]);
            let downline_3 = {
              address: downline3arr[node2],
              userid: userid3,
              downlinenode: downlinenode3,
            };
            downline3.push(downline_3);
            downline4arr = downline4arr.concat(downlinenode3);
            console.log(downline3, "downline3downline3");
          }
        }

        let line1arr = [];
        if (downline1.length > 0) {
          syncEach(
            downline1,
            async function (items, next) {
              if (
                items &&
                items.downlinenode &&
                items.downlinenode.length > 0
              ) {
                let subline = [];
                syncEach(
                  items.downlinenode,
                  async function (subitems, next1) {
                    let IndexVal = downline2.findIndex(
                      (val) => val.address == subitems
                    );
                    if (
                      IndexVal >= 0 &&
                      downline2[IndexVal].downlinenode &&
                      downline2[IndexVal].downlinenode.length > 0
                    ) {
                      let subline2 = [];
                      syncEach(
                        downline2[IndexVal].downlinenode,
                        async function (thir_items, next2) {
                          let IndexVal1 = downline3.findIndex(
                            (val) => val.address == thir_items
                          );
                          if (
                            IndexVal1 >= 0 &&
                            downline3[IndexVal1].downlinenode &&
                            downline3[IndexVal1].downlinenode.length > 0
                          ) {
                            let subline3 = [];
                            syncEach(
                              downline3[IndexVal1].downlinenode,
                              async function (fourth_items, next3) {
                                let IndexvVal = downline4.findIndex(
                                  (val) => val.address == fourth_items
                                );
                                if (
                                  IndexvVal >= 0 &&
                                  downline4[IndexvVal].downlinenode &&
                                  downline4[IndexvVal].downlinenode.length > 0
                                ) {
                                  let subline4 = [];
                                  syncEach(
                                    downline4[IndexvVal].downlinenode,
                                    async function (fifth_items, next4) {
                                      let IndexxVal = downline5.findIndex(
                                        (val) => val.address == fifth_items
                                      );
                                      let user22 = {
                                        name:
                                          "ID " + downline5[IndexxVal].userid,
                                      };
                                      subline4.push(user22);
                                      next4();
                                    },
                                    function (error, success) {
                                      let mainline = {
                                        name:
                                          "ID " + downline4[IndexvVal].userid,
                                        children: subline4,
                                      };
                                      subline3.push(mainline);
                                      next3();
                                    }
                                  );
                                } else {
                                  let Index_Val = downline4.findIndex(
                                    (val) => val.address == fourth_items
                                  );
                                  let user22 = {
                                    name: "ID " + downline4[Index_Val].userid,
                                  };
                                  subline3.push(user22);
                                  next3();
                                }
                              },
                              function (error, success) {
                                let mainline = {
                                  name: "ID " + downline3[IndexVal1].userid,
                                  children: subline3,
                                };
                                subline2.push(mainline);
                                next2();
                              }
                            );
                          } else {
                            let IndexVal = downline3.findIndex(
                              (val) => val.address == thir_items
                            );
                            let user1 = {
                              name: "ID " + downline3[IndexVal].userid,
                            };
                            subline2.push(user1);
                            next2();
                          }
                        },
                        function (error, success) {
                          let mainline = {
                            name: "ID " + downline2[IndexVal].userid,
                            children: subline2,
                          };
                          subline.push(mainline);
                          next1();
                        }
                      );
                    } else {
                      var IndexValue = downline2.findIndex(
                        (val) => val.address == subitems
                      );
                      let user1 = {
                        name: "ID " + downline2[IndexValue].userid,
                      };
                      subline.push(user1);
                      next1();
                    }
                  },
                  function (errs, t_Items) {
                    let mainline = {
                      name: "ID " + items.userid,
                      children: subline,
                    };
                    line1arr.push(mainline);
                    next();
                  }
                );
              } else {
                let mainline = {
                  name: "ID " + items.userid,
                  children: [],
                };
                line1arr.push(mainline);
                next();
              }
            },
            function (err, transformedItems) {
              let mainlinearr = {
                name: "ID " + userid,
                children: line1arr,
              };
              setSelected(mainlinearr);
              settreeprogress(false);
            }
          );
        } else {
          let mainlinearr = {
            name: "ID " + userid,
            children: [],
          };
          setSelected(mainlinearr);
          settreeprogress(false);
        }

        console.log(line1arr, "line1arrline1arr");
      }
    } catch (err) {
      console.log(err, "=====errr");
      settreeprogress(false);
    }
  }

  async function handleClick(id) {
    try {
      if (id != 0) {
        var selectId = id.slice(3);
        if (
          selectId &&
          selectId != null &&
          selectId != undefined &&
          selectId != ""
        ) {
          setsearchid("");
          let userid = selectId;
          settreeprogress(true);
          let web3 = new Web3(
            walletConnection &&
            walletConnection.provider &&
            walletConnection.provider != null &&
            walletConnection.provider != undefined &&
            walletConnection.provider != ""
              ? walletConnection.provider
              : window.ethereum
          );
          var polygonContract = new web3.eth.Contract(
            polyfundcontractabi,
            config.polyfundcontract
          );
          let c_address = await polygonContract.methods.userList(userid).call();
          console.log(c_address, "c_address");
          if (
            c_address &&
            c_address != null &&
            c_address != undefined &&
            c_address != "" &&
            c_address != "0x0000000000000000000000000000000000000000"
          ) {
            getuserdetails(c_address);
          } else {
            settreeprogress(false);
            toast.error("Enter Valid Id", toasterOption);
          }
        } else {
          settreeprogress(false);
          toast.error("Enter Valid Id", toasterOption);
        }
      } else {
      }
    } catch (err) {
      settreeprogress(false);
      toast.error("Enter Valid Id", toasterOption);
    }
  }

  async function searchbyid() {
    try {
      if (
        searchid &&
        searchid != null &&
        searchid != undefined &&
        searchid != ""
      ) {
        let userid = searchid;
        settreeprogress(true);
        let web3 = new Web3(
          walletConnection &&
          walletConnection.provider &&
          walletConnection.provider != null &&
          walletConnection.provider != undefined &&
          walletConnection.provider != ""
            ? walletConnection.provider
            : window.ethereum
        );
        var polygonContract = new web3.eth.Contract(
          polyfundcontractabi,
          config.polyfundcontract
        );
        let c_address = await polygonContract.methods.userList(userid).call();
        console.log(c_address, "c_address");
        if (
          c_address &&
          c_address != null &&
          c_address != undefined &&
          c_address != "" &&
          c_address != "0x0000000000000000000000000000000000000000"
        ) {
          getuserdetails(c_address);
        } else {
          settreeprogress(false);
          toast.error("Enter Valid Id", toasterOption);
        }
      } else {
        settreeprogress(false);
        toast.error("Enter Valid Id", toasterOption);
      }
    } catch (err) {
      settreeprogress(false);
      toast.error("Enter Valid Id", toasterOption);
    }
  }

  function getFormatMulticall(results, name, pos) {
    try {
      var index =
        results &&
        results.results &&
        results.results.details &&
        results.results.details.callsReturnContext &&
        results.results.details.callsReturnContext.findIndex(
          (val) => val.reference == name
        );
      var returnVal =
        results.results.details.callsReturnContext[index] &&
        results.results.details.callsReturnContext[index].returnValues
          ? results.results.details.callsReturnContext[index].returnValues
          : "";
      return returnVal;
    } catch (err) {
      console.log(err, "==+++++++==");
      return "";
    }
  }

  async function bignumber(inputs) {
    let ten = new BigNumber(inputs.hex, 16);
    var value = ten.toString(10);
    return value;
  }

  async function copyToClipboard() {
    var text = config.frontend + "/join-now/" + walletConnection.address;
    navigator.clipboard.writeText(text);
    toast.success("Copied Successfully", toasterOption);
  }
  async function empty() {}
  console.log(selected, "selectedselected");

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span>
          <span className="timer_time">{zeroPad(days)}</span>
          <span>days</span>
        </span>
        {/* <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(hours)}</span> 
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time">{zeroPad(minutes)}</span> 
        </span>
        <span className="timer_dots">:</span>
        <span>
          <span className="timer_time" >{zeroPad(seconds)}</span> 
        </span> */}
      </div>
    );
  };

  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="dashboard_shade">
          <div className="container">
            <div className="h_flx">
              <h3>Dashboard</h3>
              <div className="box">
               
              Affiliate link : {" "}
                  <p className="d-flex align-items-center" >
                  {/* { walletConnection?.address
                    ? walletConnection?.address
                    : "-----------------"} */}
                    https://unityfund.io/i/g9...
               
                <img
                  src={require("../assets/images/copy1.png")}
                  className="img-fluid ms-2 mb-0 h-auto"
                  onClick={() => {
                    if (walletConnection?.address != "") {
                      copyToClipboard();
                    } else {
                      empty();
                    }
                  }}
                />
                 </p>
              </div>
            </div>
            <div className="validity_box mb-4">
              <h5>Validity Period</h5>
              <div class="b_flx">
                <div className="w-100 me-lg-3" >
                <div class="progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width:"80%"}}>
                    </div>
                 </div>
                  <div className="flx" >
                    <p>Daily 9% For 12 days. (1/12)</p>
                    <p className="ylw">72 USDT / 108 USDT</p>
                  </div>
                 </div>
                 <div>
                  <button className="primary_btn" >Rejoin</button>
                 </div>
              </div>
            </div>
            <div className="bg_box mb-4">
              <div className="in_box">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/myid.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5>{userdetails?.userid ? userdetails.userid : 0}</h5>
                        <p>My ID</p>
                      </div>
                    </div>
                  </div>                     
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/refferal.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5>
                          {userdetails?.directrefferalCount
                            ? userdetails?.directrefferalCount
                            : 0}
                        </h5>
                        <p> Direct Referral </p>
                      </div>
                    </div>
                  </div> 
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/income.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {userdetails?.DirectReferralIncome
                              ? userdetails?.DirectReferralIncome /
                                config.decimals
                              : 0}
                          </h5>
                          <span className="mb-2"> (MATIC)</span>
                        </div>
                        <p> Direct Referral Income </p>
                      </div>
                    </div>
                  </div>     
                  <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/usdt.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div class="d-flex align-items-end">
                        <h5> 1234  </h5><span className="mb-2"> (USDT)</span>
                        </div>
                        <p> Total USDT claimed   </p>
                      </div>
                    </div>
                  </div>         
                  {/* <div className="col-lg-3">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/myteam.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5> 23</h5>
                        <p>My Team</p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="dash_box mb-4">
              <div className="row">
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 1</h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome1
                          ? parseFloat(userdetails?.Generationincome1) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(MATIC)</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 2</h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome2
                          ? parseFloat(userdetails?.Generationincome2) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(MATIC)</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 3</h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome3
                          ? parseFloat(userdetails?.Generationincome3) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(MATIC)</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="box">
                    <div className="bdr">
                      <p>Generation income</p>
                      <h6>level 4 </h6>
                    </div>
                    <div>
                      <h5>
                        {userdetails?.Generationincome4
                          ? parseFloat(userdetails?.Generationincome4) /
                            config.decimals
                          : 0}
                      </h5>
                      <span>(MATIC)</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg_box mb-4">
              <div className="in_box  in_boxchg ">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/tot.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {userdetails?.TotalGenerationIncome
                              ? parseFloat(userdetails?.TotalGenerationIncome) /
                                config.decimals
                              : 0}
                          </h5>
                          <span className="mb-2">(MATIC)</span>
                        </div>
                        <p> Total Generation Income </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box justify-content-lg-center my-3 my-lg-0">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/rejoin.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <h5>
                          {userdetails?.ReJoinCount
                            ? userdetails?.ReJoinCount / 1e18
                            : 0}
                        </h5>
                        <p> Re-Join Count </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="box">
                      <div className="img_box">
                        <img
                          src={require("../assets/images/rejoin_topup.png")}
                          className="img-fluid"
                        />
                      </div>
                      <div>
                        <div className="d-flex align-items-end">
                          <h5>
                            {userdetails.RejoinTopupAmount
                              ? parseFloat(userdetails.RejoinTopupAmount) /
                                config.decimals
                              : 0}
                          </h5>
                          <span className="mb-2">(MATIC)</span>
                        </div>
                        <p> Rejoin Topup Amount </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="dash_box mb-4 income_boxes">
              <div className="row">
                <div className="col-lg-6 d-flex mb-3 mb-lg-0">
                  <div className="box flex-row justify-content-between">
                  <div>
                     <div class="d-flex align-items-end"><h5>12345 </h5><span className="mb-3">(USDT)</span></div>
                      <p> ROI + Direct Referral Income  </p>
                  </div>
                  <button className="primary_btn" >Withdraw</button>
                  </div>
                </div>
                <div className="col-lg-6 d-flex">
                  <div className="box flex-row justify-content-between">
                  <div>
                     <div class="d-flex align-items-end"><h5>12345 </h5><span className="mb-3">(USDT)</span></div>
                      <p>Total Generation Income </p>
                  </div>
                  <div className="text-end" >
                  <button className="primary_btn mb-2" >Withdraw</button>
                  <span className="d-block">(Need 3 direct referrals to withdraw)</span>
                  </div>
                  </div>
                </div>
                </div>
              </div>


            {/* <div className="dash_box booster mb-4">

              <div className="abv_flx">
                <h3>Booster Income</h3>
                <div>
                  <span className="me-2">
                    Available Amount : <span>400</span>
                  </span>
                  <button className="primary_btn">Withdraw</button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 col-xl-4">
                  <div className="box">
                    <div className="fl_x">
                      <div className="b_dr">
                        <h6 className="mb-3">level 1</h6>
                        <h5>30</h5>
                        <span>(MATIC)</span>
                      </div>
                      <div className="amount">
                        <p className="mb-3"> Remaining Matic </p>
                        <div className="empty">----</div>
                      </div>
                    </div>
                    <div className="b_flx">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div>
                        <p>100%</p>
                      </div>
                    </div>
                    <div className="flx justify-content-between">
                      <div className="d-flex align-items-center">
                        <div className="tic">
                          <img
                            src={require("../assets/images/tic.png")}
                            className="img-fluid"
                          />
                        </div>
                        <span>Auto Upgrade</span>
                      </div>
                      <span>Auto Upgrade</span>
                      </div> 
                      <Countdown date="2023-09-30T01:02:03" renderer={renderer} /> 
                    </div>

                      <div className="flx justify-content-between" >
                        <span className="claim">50 USDT</span>
                        <button className="primary_btn px-4 py-1" >Claim</button> 
                      </div>

                  </div>
                </div>
                <div className="col-lg-6 col-xl-4">
                  <div className="box">
                    <div className="fl_x">
                      <div className="b_dr">
                        <h6 className="mb-3">level 2</h6>
                        <h5>270</h5>
                        <span>(MATIC)</span>
                      </div>
                      <div className="amount">
                        <p className="mb-3"> Remaining Matic </p>
                        <div className="empty">----</div>
                      </div>
                    </div>
                    <div className="b_flx">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div>
                        <p>100%</p>
                      </div>
                    </div>
                    <div className="flx">
                      <div className="tic">
                        <img
                          src={require("../assets/images/tic.png")}
                          className="img-fluid"
                        />
                      </div>
                      <span>Auto Upgrade</span>
                    </div>
                      <div className="flx justify-content-between" >
                      <button className="primary_btn px-4 py-1" >Claim</button>
                       <Countdown date="2023-09-30T01:02:03" renderer={renderer} />
                       </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-4">
                  <div className="box">
                    <div className="fl_x">
                      <div className="b_dr">
                        <h6 className="mb-3">level 3</h6>
                        <h5>3645</h5>
                        <span>(MATIC)</span>
                      </div>
                      <div className="amount">
                        <p className="mb-3"> Remaining Matic </p>
                        <div className="empty">----</div>
                      </div>
                    </div>
                    <div className="b_flx">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "100%" }}
                          aria-valuenow="25"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div>
                        <p>100%</p>
                      </div>
                    </div>
                    <div className="flx">
                      <div className="tic">
                        <img
                          src={require("../assets/images/tic.png")}
                          className="img-fluid"
                        />
                      </div>
                      <span>Auto Upgrade</span>
                    </div>
                      <div className="flx justify-content-between" >
                      <button className="primary_btn px-4 py-1" >Claim</button>
                       <Countdown date="2023-09-30T01:02:03" renderer={renderer} />
                       </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-4">
                  <div className="box current">
                    <div className="fl_x">
                      <div className="b_dr">
                        <h6 className="mb-3">level 4</h6>
                        <h5>5000</h5>
                        <span>(MATIC)</span>
                      </div>
                      <div className="amount">
                        <p className="mb-3"> Remaining Matic </p>
                        <h5>467.50</h5>
                      </div>
                    </div>
                    <div className="b_flx">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "80%" }}
                          aria-valuenow="80"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div>
                        <p>80%</p>
                      </div>
                    </div>
                    <div className="flx">
                      <div className="tic">
                        <img
                          src={require("../assets/images/tic.png")}
                          className="img-fluid"
                        />
                      </div>
                      <span>Auto Upgrade</span>
                    </div>
                      <div className="flx justify-content-between" >
                      <button className="primary_btn px-4 py-1" >Claim</button>
                       <Countdown date="2023-09-30T01:02:03" renderer={renderer} />
                       </div>
                  </div>
                </div>  
                <div className="col-lg-6 col-xl-4" >
                  <div className="box inactive">
                    <div className="fl_x" >
                    <div className="b_dr">
                      <h6 className="mb-3">level 5</h6>
                      <div className="empty" >----</div>
                      <span>(MATIC)</span>
                    </div>
                    <div className="amount" >
                      <p className="mb-3" > Remaining Matic  </p>
                      <h5>24,603.75</h5>
                    </div>
                    </div> 
                    <div className="b_flx" >
                      <div class="progress">
                          <div class="progress-bar" role="progressbar" style={{width:"0%"}} aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                      <div>
                         <p>0%</p> 
                      </div>
                    </div>
                    <div className="flx" >
                      <div className="tic">
                        <img src={require("../assets/images/tic.png")} className="img-fluid" />
                      </div>
                      <span>Auto Upgrade</span>
                    </div>
                      <div className="flx justify-content-between" >
                      <button className="primary_btn px-4 py-1" >Claim</button>
                       <Countdown date="2023-09-30T01:02:03" renderer={renderer} />
                       </div>
                  </div>
                </div>
                <div className="col-lg-6 col-xl-4">
                  <div className="box inactive">
                    <div className="fl_x">
                      <div className="b_dr">
                        <h6 className="mb-3">level 5</h6>
                        <div className="empty">----</div>
                        <span>(MATIC)</span>
                      </div>
                      <div className="amount">
                        <p className="mb-3"> Remaining Matic </p>
                        <h5>24,603.75</h5>
                      </div>
                    </div>
                    <div className="b_flx">
                      <div class="progress">
                        <div
                          class="progress-bar"
                          role="progressbar"
                          style={{ width: "0%" }}
                          aria-valuenow="0"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      <div>
                        <p>0%</p>
                      </div>
                    </div>
                    <div className="flx">
                      <div className="tic">
                        <img
                          src={require("../assets/images/tic.png")}
                          className="img-fluid"
                        />
                      </div>
                      <span>Auto Upgrade</span>
                    </div>
                        <div className="flx justify-content-between" >
                      <button className="primary_btn px-4 py-1" >Claim</button>
                       <Countdown date="2023-09-30T01:02:03" renderer={renderer} />
                       </div>  
                  </div>
                </div>
              </div>
            </div> */}
            <div className="bg_box tree mb-4">
              <div className="t_flx">
                <h3>Genealogy Tree</h3>
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search ID"
                    value={searchid}
                    disabled={walletConnection.address == "" ? "disabled" : ""}
                    onChange={(e) => setsearchid(e.target.value)}
                    // onKeyPress={() => {
                    //   if (walletConnection?.address != "") {
                    //     searchbyid();
                    //   } else {
                    //     empty();
                    //   }
                    // }}
                  />
                  <button
                    // class="input-group-text"
                    className="primary_btn ms-2 d-flex align-items-center"
                    style={{ cursor: "pointer", padding: "10px 20px" }}
                    onClick={() => {
                      if (walletConnection?.address != "") {
                        searchbyid();
                      } else {
                        empty();
                      }
                    }}
                  >
                    {/* <img
                      src={require("../assets/images/search.png")}
                      className="img-fluid"
                    /> */}
                    Search
                  </button>
                </div>
              </div>
              <Scrollbars style={{ width: "100%", height: 450 }}>
                <div className="plan_pool">
                  {!treeprogress ? (
                    <>
                      <div className="plan_pool_head">
                        <img
                          src={require("../assets/images/user_1.png")}
                          className="img-fluid"
                          alt="Icon"
                          data-tooltip-id="my-tooltip-1"
                        />
                        {/* <h5>{alltreedata?.tree1}</h5> */}
                      </div>
                      <ReactTooltip
                        id="my-tooltip-1"
                        place="bottom"
                        content={alltreedata?.tree1}
                      />
                      {/* First Row */}
                      <div className="plan_pool_first_row">
                        <div className="plan_pool_head_child">
                          <img
                            src={require("../assets/images/user_3_blue.png")}
                            className={
                              alltreedata?.tree2 == 0
                                ? "img-fluid isuser"
                                : "img-fluid"
                            }
                            alt="Icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(alltreedata?.tree2)}
                          />
                          <h5>{alltreedata?.tree2}</h5>
                        </div>
                        <div
                          className="plan_pool_head_child"
                          data-tip
                          data-for="id_tooltip2"
                        >
                          <img
                            src={require("../assets/images/user_3_blue.png")}
                            className={
                              alltreedata?.tree3 == 0
                                ? "img-fluid isuser"
                                : "img-fluid"
                            }
                            alt="Icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(alltreedata?.tree3)}
                          />
                          <h5>{alltreedata?.tree3}</h5>
                        </div>
                        <div
                          className="plan_pool_head_child"
                          data-tip
                          data-for="id_tooltip2"
                        >
                          <img
                            src={require("../assets/images/user_3_blue.png")}
                            className={
                              alltreedata?.tree4 == 0
                                ? "img-fluid isuser"
                                : "img-fluid"
                            }
                            alt="Icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleClick(alltreedata?.tree4)}
                          />
                          <h5>{alltreedata?.tree4}</h5>
                        </div>
                      </div>

                      {/* Second Row */}
                      <div className="plan_pool_second_row">
                        <div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip3"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree5 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree5)}
                            />
                            <h6>{alltreedata?.tree5}</h6>
                          </div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip4"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree6 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree6)}
                            />
                            <h6>{alltreedata?.tree6}</h6>
                          </div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip4"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree7 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree7)}
                            />
                            <h6>{alltreedata?.tree7}</h6>
                          </div>
                        </div>

                        <div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip5"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree8 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree8)}
                            />
                            <h6>{alltreedata?.tree8}</h6>
                          </div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip6"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree9 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree9)}
                            />
                            <h6>{alltreedata?.tree9}</h6>
                          </div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip6"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree10 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree10)}
                            />
                            <h6>{alltreedata?.tree10}</h6>
                          </div>
                        </div>

                        <div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip5"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree11 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree11)}
                            />
                            <h6>{alltreedata?.tree11}</h6>
                          </div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip6"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree12 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree12)}
                            />
                            <h6>{alltreedata?.tree12}</h6>
                          </div>
                          <div
                            className="plan_pool_head_child"
                            data-tip
                            data-for="id_tooltip6"
                          >
                            <img
                              src={require("../assets/images/user_3_blue.png")}
                              className={
                                alltreedata?.tree13 == 0
                                  ? "img-fluid isuser"
                                  : "img-fluid"
                              }
                              alt="Icon"
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClick(alltreedata?.tree13)}
                            />
                            <h6>{alltreedata?.tree13}</h6>
                          </div>
                        </div>
                      </div>

                      {/* third Row */}
                      <div className="plan_pool_third_row">
                        <div className="plan_pool_second_row">
                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip3"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree14 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree14)}
                              />
                              <h6>{alltreedata?.tree14}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip4"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree15 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree15)}
                              />
                              <h6>{alltreedata?.tree15}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip4"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree16 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree16)}
                              />
                              <h6>{alltreedata?.tree16}</h6>
                            </div>
                          </div>

                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip5"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree17 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree17)}
                              />
                              <h6>{alltreedata?.tree17}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree18 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree18)}
                              />
                              <h6>{alltreedata?.tree18}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree19 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree19)}
                              />
                              <h6>{alltreedata?.tree19}</h6>
                            </div>
                          </div>

                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip5"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree20 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree20)}
                              />
                              <h6>{alltreedata?.tree20}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree21 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree21)}
                              />
                              <h6>{alltreedata?.tree21}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree22 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree22)}
                              />
                              <h6>{alltreedata?.tree22}</h6>
                            </div>
                          </div>
                        </div>

                        <div className="plan_pool_second_row">
                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip3"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree23 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree23)}
                              />
                              <h6>{alltreedata?.tree23}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip4"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree24 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree24)}
                              />
                              <h6>{alltreedata?.tree24}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip4"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree25 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree25)}
                              />
                              <h6>{alltreedata?.tree25}</h6>
                            </div>
                          </div>

                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip5"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree26 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree26)}
                              />
                              <h6>{alltreedata?.tree26}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree27 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree27)}
                              />
                              <h6>{alltreedata?.tree27}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree28 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree28)}
                              />
                              <h6>{alltreedata?.tree28}</h6>
                            </div>
                          </div>

                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip5"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree29 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree29)}
                              />
                              <h6>{alltreedata?.tree29}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree30 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree30)}
                              />
                              <h6>{alltreedata?.tree30}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree31 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree31)}
                              />
                              <h6>{alltreedata?.tree31}</h6>
                            </div>
                          </div>
                        </div>

                        <div className="plan_pool_second_row">
                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip3"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree32 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree32)}
                              />
                              <h6>{alltreedata?.tree32}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip4"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree33 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree33)}
                              />
                              <h6>{alltreedata?.tree33}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip4"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree34 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree34)}
                              />
                              <h6>{alltreedata?.tree34}</h6>
                            </div>
                          </div>

                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip5"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree35 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree35)}
                              />
                              <h6>{alltreedata?.tree35}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree36 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree36)}
                              />
                              <h6>{alltreedata?.tree36}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree37 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree37)}
                              />
                              <h6>{alltreedata?.tree37}</h6>
                            </div>
                          </div>

                          <div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip5"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree38 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree38)}
                              />
                              <h6>{alltreedata?.tree38}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree39 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree39)}
                              />
                              <h6>{alltreedata?.tree39}</h6>
                            </div>
                            <div
                              className="plan_pool_head_child"
                              data-tip
                              data-for="id_tooltip6"
                            >
                              <img
                                src={require("../assets/images/user_3_blue.png")}
                                className={
                                  alltreedata?.tree40 == 0
                                    ? "img-fluid isuser"
                                    : "img-fluid"
                                }
                                alt="Icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClick(alltreedata?.tree40)}
                              />
                              <h6>{alltreedata?.tree40}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div>
                      <h4>
                        <center>
                          <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Processing....Please Wait
                        </center>
                      </h4>
                    </div>
                  )}
                </div>
              </Scrollbars>
            </div>
            {/* <div className="bg_box mb-4 history">
               <h3 className="mb-4" >Recent Deposit History</h3>
               <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col"> Time </th>
                      <th scope="col">Amount</th>
                      <th scope="col">User ID</th>
                      <th scope="col">Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>2023-09-02      12:06 AM</td>
                      <td>123456 </td>
                      <td>ID 1210781</td>
                      <td>fdfdf4df4dfdfd8fdf1df51d6f....  </td>
                    </tr>
                    <tr>
                      <td>2023-09-02      12:06 AM</td>
                      <td>123456 </td>
                      <td>ID 1210781</td>
                      <td>fdfdf4df4dfdfd8fdf1df51d6f....  </td>
                    </tr>
                    <tr>
                      <td>2023-09-02      12:06 AM</td>
                      <td>123456 </td>
                      <td>ID 1210781</td>
                      <td>fdfdf4df4dfdfd8fdf1df51d6f....  </td>
                    </tr>
                  </tbody>
                </table>
               </div>
               
            </div> */}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
