let key = {};

let toasterOption = {
  position: "top-right",
  autoClose: 8000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
let Envname = "demo";

if (Envname === "demo") {
  // console.log("Set demo Config");
  // const API_URL = "https://angelpupapi.wearedev.team";
  // key = {
  //   baseUrl: `${API_URL}`,
  //   frontend: "https://www.angelpupp.com",
  //   polyfundcontract: "0x5C9BFB6A0a23A2f6773CE4e50Df356A6433619EF", //live
  // USDTContractAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
  //   networkVersion: 97,
  //   toasterOption: toasterOption,
  // adminaddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
  //   // livechainid : 56,
  //   NetworkId: 56,
  //   rpcUrl: "https://bsc-dataseed1.binance.org",
  // };
} else if (Envname === "demo") {
  console.log("Set demo Config");
  key = {
    frontend: "https://polyfund-frontend.pages.dev",
    polyfundcontract: "0x42b79e110d620eC16829D2A4b970F966e2276D45", //demo
    USDTContractAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    adminaddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    toasterOption: toasterOption,
    NetworkId: 97,
    decimals:1e18,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    dataUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
  };
} else {
  console.log("Set Development Config");
  key = {
    frontend: "http://localhost:3000",
    polyfundcontract: "0x70cda58145DD88aaCA9EDc9464e237fE9D9c82df", //local
    USDTContractAddress: "0x8CE7720fD183AeC96b676FD8250724b05b0d7a6F",
    adminaddress: "0x80Aa595c83430288E3A75F4D03bE15b72385420F",
    toasterOption: toasterOption,
    NetworkId: 97,
    decimals:1e18,
    rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
    dataUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
  };
}

export default key;
